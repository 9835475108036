import React from 'react';

import { ApolloProvider } from 'react-apollo';
import { IntlProvider } from 'react-intl';
import flatten from 'flat';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import numbro from 'numbro';

import localeMessages from '../../locale_messages.yml';
import client from '../apollo-client';
import Routes from '../Routes';
import theme from '../themes/main';

import ToastContainer from '../../components/ToastContainer';

numbro.registerLanguage({
  languageTag: 'pt-BR',
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'mil',
    million: 'milhões',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => 'º',
  currency: {
    symbol: 'R$ ',
    position: 'prefix'
  }
})

numbro.setLanguage('pt-BR');

numbro.setDefaults({
  thousandSeparated: true,
  mantissa: 2
})

numbro.zeroFormat(' - ');

class Root extends React.Component {
  render() {
    let locale = localStorage.getItem('locale') || 'id';

    // Prevent errors on old versions where the Indonesia locale key was 'in'
    if (locale === 'in') {
      localStorage.setItem('locale', 'id');
      window.location.href = '/';
    }

    return (
      <ApolloProvider client={ client }>
        <ThemeProvider theme={ theme }>
          <StylesProvider injectFirst>
            <MuiPickersUtilsProvider utils={ MomentUtils }>
              <IntlProvider key={ locale } locale={ locale } messages={ flatten(localeMessages[locale]) }>
                <Routes />
                <ToastContainer />
              </IntlProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    );
  }
}

export default Root;
