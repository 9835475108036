import _ from 'lodash'
import localeMessages from '../locale_messages.yml'

const onLocaleFunctions = _.mapValues(localeMessages, (_messages, locale) => _.flow(
  _.property('i18NStringsByStringIdList'),
  _.partial(_.find, _, { language: locale }),
  _.property('stringValue')
))

const onLocaleFunctionsCustom = _.mapValues(localeMessages, (_messages, locale) => _.flow(
  _.partial(_.find, _, { language: locale }),
  _.property('stringValue')
))

export function onCurrentLocale(multiLanguageStringObject) {
  const locale = localStorage.getItem('locale') || 'id'
  return onLocaleFunctions[locale](multiLanguageStringObject)
}

export function onCurrentLocaleCustom(multiLanguageStringObject) {
  const locale = localStorage.getItem('locale') || 'id'
  return onLocaleFunctionsCustom[locale](multiLanguageStringObject)
}
