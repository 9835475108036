export default [
  {
    index: 0,
    key: "indonesia_limit",
    labelKey: "indonesia_limit",
    color: "#FF0000",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:indonesia_limit"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: '' },
    //   { labelKey: 'year', label: 'Ano', value: '' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/coastal_line.zip'
  },
  {
    index: 1,
    key: "province",
    labelKey: "province",
    color: "#947438",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:province"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: '' },
    //   { labelKey: 'year', label: 'Ano', value: '' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/province.zip'
  },
  {
    index: 2,
    key: "district",
    labelKey: "district",
    color: "#FFFF00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:district"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'IBGE' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/district.zip'
  },
  {
    index: 3,
    key: "subdistrict",
    labelKey: "subdistrict",
    color: "#800080",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:subdistrict"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'INPE - Terrabrasilis' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/sub_district.zip'
  },
  {
    index: 4,
    key: "village",
    labelKey: "village",
    color: "#2980B9",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:village"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'ANA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/village.zip'
  },
  {
    index: 5,
    key: "new_national_capital",
    labelKey: "new_national_capital",
    color: "#FFC0CB",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:new_national_capital"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'ANA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/new_national_capital.zip'
  },
  {
    index: 6,
    key: "forest_estate",
    labelKey: "forest_estate",
    color: "#00FF00", 
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:forest_estate"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'MMA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/forest_estate.zip'
  },
  {
    index: 7,
    key: "conservation_forest",
    labelKey: "conservation_forest",
    color: "#008080",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:conservation_forest"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'Funai' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/conservation_forest.zip'
  },
  {
    index: 8,
    key: "peat_ecosystem_function",
    labelKey: "peat_ecosystem_function",
    color: "#fc795b",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:peat_ecosystem_function"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'Incra' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/peatland_ecological_function.zip'
  },
  {
    index: 9,
    key: "primary_forests_and_peatlands_moratorium",
    labelKey: "primary_forests_and_peatlands_moratorium",
    color: "#7f6b83",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:primary_forests_and_peatlands_moratorium"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'Incra' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/forest_moratorium.zip'
  },
  {
    index: 10,
    key: "social_forestry",
    labelKey: "social_forestry",
    color: "#40E0D0",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:social_forestry"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'MMA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/social_forestry.zip'
  },
  {
    index: 11,
    key: "pulpwood_concession",
    labelKey: "pulpwood_concession",
    color: "#A52A2A",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:pulpwood_concession"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'MMA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/pulpwood_concession.zip'
  },
  {
    index: 12,
    key: "selective_logging_concession",
    labelKey: "selective_logging_concession",
    color: "#9300ff",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:selective_logging_concession"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'MMA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/selective_logging_concession.zip'
  },
  {
    index: 13,
    key: "mining_concession",
    labelKey: "mining_concession",
    color: "#ffcd00",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:mining_concession"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'MMA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/mining_concession.zip'
  },
  {
    index: 14,
    key: "oil_palm_concession",
    labelKey: "oil_palm_concession",
    color: "#00ff7c",
    props: {
      url: "https://shared-geoserver.mapbiomas.org/geoserver/ows",
      transparent: true,
      format: "image/png",
      layers: "mapbiomas-indonesia:oil_palm_concession"
    },
    // descriptionData: [
    //   { labelKey: 'source', label: 'Fonte', value: 'MMA' },
    //   { labelKey: 'year', label: 'Ano', value: '2019' },
    // ],
    downloadUrl: 'https://storage.cloud.google.com/shared-development-storage/COLECOES/INDONESIA/COLECAO2/SHAPES-ESTATISTICAS/palm_oil_concession.zip'
  },
];