import React, { useEffect, useRef, useState } from 'react';
import Map, { NavigationControl, Source, Layer } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import classnames from 'classnames';
import numbro from 'numbro';

mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

import { makeStyles, withStyles } from '@material-ui/core/styles';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import Tooltip from '@material-ui/core/Tooltip';

import CoordinatesInfo from '../MapCanvas/components/CoordinatesInfo';

import 'mapbox-gl/dist/mapbox-gl.css';

import useFormatMessage from '../../../../../../hooks/useFormatMessage';
import useWindowSize from '../../../../../../hooks/useWindowSize';

const EXTERNAL_ELEMENTS_HEIGHT = 30;

import styles from './MapboxCanvas.module.scss';

const useStyles = makeStyles((theme) => ({
  mapButtonActive: {
    backgroundColor: '#F1F1F1',
    '& svg': {
      fill: `${theme.palette.primary.main} !important`
    }
  }
}));

const CustomTooltip = withStyles(() => ({
  tooltip: {
    padding: '8px 12px',
    fontSize: 12,
    fontWeight: 400,
    color: 'white',
    backgroundColor: '#444444',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.12)',
  },
  arrow: {
    color: '#444444'
  },
}))(Tooltip);

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ2xhdWNvbXVuc2JlcmciLCJhIjoiY2wzczI4dmMyMW1kdjNqcGVhdTdxbDN5ciJ9.WSNrzmo52BqUJFJOCm1hAg';

const CONFIG_DEFAULT = {
  lat: -23.8294,
  lng: -45.4531,
  zoom: 10.01,
  bearing: 0,
  pitch: 54,
  exaggeration: 1.5//,
  //exaggeration: ['^', ['get', 'z'], 0.9]
};

export default function MapboxCanvas({
  activeLayers,
  activeYear,
  baseParams,
  configOptions,
  flyTo,
  headerIsVisible,
  isMobile,
  layersOpacity,
  onSearchPointClick,
  onMoveEnd,
}) {
  const CONFIG = { ...CONFIG_DEFAULT, ...configOptions };
  const classes = useStyles();
  const mapRef = useRef();
  const windowSize = useWindowSize();
  const formatMessage = useFormatMessage();
  const [isOnPointSearchMode, setIsOnPointSearchMode] = useState(false);
  const [mouseCoordinates, setMouseCoordinates] = useState(null);

  useEffect(() => {
    if (mapRef && mapRef.current && flyTo && _.isArray(flyTo)) {
      if (_.isArray(_.first(flyTo))) {
        mapRef.current.fitBounds([
          _.reverse(_.first(flyTo)),
          _.reverse(_.last(flyTo)),
        ]);
      } else {
        mapRef.current.panTo(flyTo, 10);
      }
    }
  }, [flyTo]);

  const handleMapClick = (event) => {
    if (isOnPointSearchMode && mapRef.current) {
      const { lat, lng } = event.lngLat;
      const { x, y } = event.point;
      const point1 = { x, y: y - 1 };
      const point2 = { x, y: y + 1 };

      const parsedPoint1 = mapRef.current.layerPointToLatLng(point1);
      const parsedPoint2 = mapRef.current.layerPointToLatLng(point2);
      
      onSearchPointClick({
        __typename: 'MapPointInfo',
        parsedBBox: [
          _.get(parsedPoint2, 'lng'),
          _.get(parsedPoint2, 'lat'),
          _.get(parsedPoint1, 'lng'),
          _.get(parsedPoint1, 'lat'),
        ],
        latlng: [lat, lng],
      });
    }
  };

  const handleMouseMove = (event) => {
    const { lat, lng } = event.lngLat;
    const formatNumber = (value) => {
      const formattedValue = numbro(value).format({
        thousandSeparated: true,
        mantissa: 2
      });

      return _.replace(formattedValue, ',', '.');
    };

    setMouseCoordinates(`${formatNumber(lat)}, ${formatNumber(lng)}`);
  };

  const handleMoveEnd = (event) => {
    const viewState = _.get(event, 'viewState');

    if (viewState) {
      const {
        latitude,
        longitude,
        zoom
      } = viewState;
      const combinedValue = `${latitude.toFixed(6)},${longitude.toFixed(6)},${zoom}`;

      onMoveEnd(combinedValue);
    }
  };

  const territories = _.get(baseParams, 'territories');

  if (!territories || _.isEmpty(territories)) {
    return null;
  }

  const territoryIds = _.join(_.map(territories, 'id'), ',');

  const renderCoverageLayer = () => {

    return (
      <Source
        id="mapbiomas-brasil-3d"
        type="raster"
        key={ `coverage-3d-${ territoryIds }-${ activeYear }` }
        tiles={ [
          `${process.env.REACT_APP_WMS_BASE_URL}/wms/coverage.map?service=WMS&request=GetMap&layers=coverage&styles=&format=image%2Fpng&transparent=true&version=1.1.1&territory_ids=${ territoryIds }&year=${ activeYear }&class_tree_node_ids=1%2C7%2C8%2C9%2C10%2C2%2C11%2C12%2C13%2C14%2C15%2C16%2C3%2C17%2C18%2C27%2C37%2C38%2C39%2C40%2C41%2C28%2C42%2C43%2C44%2C19%2C20%2C4%2C21%2C22%2C23%2C24%2C5%2C25%2C26%2C6&width=256&height=256&srs=EPSG%3A3857&bbox={bbox-epsg-3857}`
        ] }
        tileSize={ 256 }
      >
        <Layer
          id="layer-mapbiomas-brasil-3d"
          sourceLayer="layer-mapbiomas-brasil-3d"
          type="raster"
          source="layer-mapbiomas-brasil-3d"
          paint={ {
            'raster-opacity': layersOpacity / 100
          } }
        />
      </Source>
    );
  };

  const renderShadowLayer = () => {

    return (
      <Source
        id="earthengine-shadow"
        key={ `earthengine-shadow-3d-${ territoryIds }-${ activeYear }` }
        type="raster"
        tiles={ [
          `${process.env.REACT_APP_WMS_BASE_URL}/wms/3d_context.map?service=WMS&request=GetMap&layers=3d_context&styles=&format=image%2Fpng&transparent=true&version=1.1.1&SRS=epsg:3857&width=256&height=256&bbox={bbox-epsg-3857}&territory_ids=${territoryIds }`
        ] }
        tileSize={ 512 }
        minZoom={ 0 }
        maxZoom={ 14 }
      >
        <Layer
          id="earthengine-shadow"
          sourceLayer="earthengine-shadow"
          type="raster"
          source="earthengine-shadow"
          paint={ {
            'raster-opacity': layersOpacity / 100
          } }
        />
      </Source>
    );
  };


  return (
    <Map
      ref={mapRef}
      initialViewState={{
        longitude: CONFIG.lng,
        latitude: CONFIG.lat,
        zoom: CONFIG.zoom,
        pitch: CONFIG.pitch,
        bearing: CONFIG.bearing,
      }}
      maxPitch={85}
      style={{ width: '100%', height: windowSize.height - EXTERNAL_ELEMENTS_HEIGHT }}
      mapStyle="mapbox://styles/joaosiqueira/cl2z3nryn003i14qn82b0es7r"
      mapboxAccessToken={MAPBOX_TOKEN}
      terrain={{ source: 'mapbox-dem', exaggeration: CONFIG.exaggeration }}
      fog={{
        'color': 'rgba(186, 210, 235,0.3)', // Lower atmosphere
        'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
        'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
        'space-color': 'rgb(11, 11, 25)', // Background color
        'star-intensity': 0.6 // Background star brightness (default 0.35 at low zoooms )
      }}
      onClick={handleMapClick}
      onMouseMove={handleMouseMove}
      onMoveEnd={handleMoveEnd}
    >
      <Source
        id="mapbox-dem"
        type="raster-dem"
        url="mapbox://mapbox.mapbox-terrain-dem-v1"
        tileSize={512}
        maxZoom={4}
      />
      { renderShadowLayer() }
      {renderCoverageLayer()}
      {/* <NavigationControl position="bottom-left" /> */}
      <div
        className={classnames({
          [styles.mapWrapperWithHeader]: headerIsVisible,
        })}
      >
        {/* { !(isMobile && showDashboardInfo) &&
          <CustomTooltip arrow title={ formatMessage('map_controls.point_info.title') } placement="right">
            <button
              className={ classnames(styles.mapActionButton, styles.mapSearchButton, {
                [classes.mapButtonActive]: isOnPointSearchMode
              }) }
              onClick={ togglePointSearchMode }
            >
              <NotListedLocationIcon />
            </button>
          </CustomTooltip>
        } */}
        <CoordinatesInfo
          headerIsVisible={headerIsVisible}
          mouseCoordinates={mouseCoordinates}
        />
      </div>
    </Map>
  );
}
