import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import numbro from 'numbro';
import { FormattedMessage } from 'react-intl';
import { useQuery } from '@apollo/react-hooks';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import LineChart from '../../../StatisticsBox/components/LineChart';
import PieChart from '../../../StatisticsBox/components/PieChart';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from '../../InfrastructureStatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CATEGORIES_OF_OBJECT_TREE_NODES,
} from './query';

const formatNumber = (value) => {
  return numbro(value).format({
    thousandSeparated: true,
    mantissa: 0
  });
};

export default function InfrastructureMainStatisticsContent({
  activeYear,
  baseParams,
  statisticsDataIsVisible,
  onShowData,
}) {
  const locale = localStorage.getItem('locale') || 'id';
  const formatMessage = useFormatMessage();

  const [chartsData, setChartsData] = useState(null);

  const territories = _.get(baseParams, 'territories');
  const yearRange = _.map(_.split(_.get(baseParams, 'yearRange'), '-'), Number);
  const years = _.range(_.first(yearRange), _.last(yearRange) + 1);
  const territoryIds = _.map(territories, 'id');
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const activeClassTreeOptionValue = _.get(baseParams, 'activeClassTreeOptionValue');
  const activeObjectTreeNodeIds = _.get(baseParams, 'activeObjectTreeNodeIds');
  const bufferId = _.get(baseParams, 'buffer');
  const objectTreeNodesData = useQuery(GET_CATEGORIES_OF_OBJECT_TREE_NODES, {
    variables: {
      objectTreeNodeIds: activeObjectTreeNodeIds
    }
  });
  const activeObjectCategoryIds = _.map(_.get(objectTreeNodesData, 'data.geospatialObjectCategoryTreeNodesList'), 'categoryId');

  if (!activeObjectCategoryIds) {
    return null;
  }

  const { data: baseData, loading: loadingBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      territoryIds,
      activeClassTreeNodeIds,
      hasObjectFilter: true,
      activeObjectCategoryIds,
      bufferId,
      classTreeKey: activeClassTreeOptionValue,
    },
    skip: _.isEmpty(territoryIds) || !activeClassTreeOptionValue,
  });

  const classTree = _.get(baseData, 'classTreeByKey');
  const isEmpty = _(_.get(baseData, 'classTreeByKey[0].mvClassTreeLevelsUi'))
    .map('classTreeNode.coverageClassificationDataByTreeListWithObjectIntersection')
    .flatten()
    .compact()
    .value().length === 0;

  const parseChartsData = () => {
    const classTreeLevelsList = _.get(classTree, '[0].mvClassTreeLevelsUi');
    const activeClassTreeLevelsList = _.filter(classTreeLevelsList, (item) => {
      return _.includes(activeClassTreeNodeIds, item.id);
    });
    const classTreeLevelsListOnLevel1 = _.filter(activeClassTreeLevelsList, { level: 1 });

    const classViewChartData = _.map(classTreeLevelsListOnLevel1, (classTree) => {
      const stringList = _.get(classTree, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const valueItems = _.get(classTree, 'classTreeNode.coverageClassificationDataByTreeListWithObjectIntersection');
      const filteredValueItems = _.filter(valueItems, { year: activeYear });
      const value = _.sumBy(filteredValueItems, 'areaInHa');

      return {
        name: _.get(selectedString, 'stringValue'),
        value,
        itemStyle: {
          color: _.get(classTree, 'color')
        }
      }
    });

    const historicalSeriesChartData = _.map(activeClassTreeLevelsList, (classTree) => {
      const stringList = _.get(classTree, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const name = `${ _.join(_.get(classTree, 'positionInTree'), '.') }. ${ _.get(selectedString, 'stringValue') }`;
      const color = _.get(classTree, 'color');
      const level = _.get(classTree, 'level');
      const valueItems = _.get(classTree, 'classTreeNode.coverageClassificationDataByTreeListWithObjectIntersection');
      const data = _.map(years, (year) => {
        const filteredValueItems = _.filter(valueItems, { year: year });
        const value = _.sumBy(filteredValueItems, 'areaInHa');

        return !_.isEmpty(filteredValueItems) ? value : null;
      });

      return {
        name,
        color,
        data,
        level,
        padding: level * 12,
      };
    });

    return {
      years,
      classViewChartData,
      historicalSeriesChartData,
    };
  };

  useEffect(() => {
    if (!loadingBaseData && baseData) {
      // parseChartsData();
      setChartsData(parseChartsData());
    }
  // }, [baseData, loadingBaseData, loadingClassTreeData, activeClassesLevelsListItems, activeYear]);
  }, [baseData, loadingBaseData, activeYear]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const renderClassViewChart = () => {
    // const { classViewChartData } = parseChartsData();
    // const data = classViewChartData;
    const data = _.get(chartsData, 'classViewChartData');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title" values={{ year: activeYear }} /></h2>
        <PieChart
          serie={{
            title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.unit'),
            data
          }}
        />
      </div>
    );
  };

  const renderHistoricalSeriesChart = () => {
    const years = _.get(chartsData, 'years');
    const data = _.get(chartsData, 'historicalSeriesChartData');

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title" /></h2>
        <LineChart
          categories={ years }
          series={ data }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const classViewChartData = _.get(chartsData, 'classViewChartData');
    const classViewChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title', { year: activeYear }),
      columns: ['Total'],
      rows: _.map(classViewChartData, (item) => {
        return {
          name: _.get(item, 'name'),
          data: [_.get(item, 'value')],
        };
      }),
    };

    const years = _.get(chartsData, 'years');
    const historicalSeriesChartData = _.get(chartsData, 'historicalSeriesChartData');
    const historicalSeriesChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.historical_line.title'),
      columns: years,
      rows: historicalSeriesChartData,
    };

    onShowData([
      classViewChart,
      historicalSeriesChart,
    ]);
  };

  return (
    <Fragment>
      { loadingBaseData &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { !isEmpty &&
        <Fragment>
          { renderClassViewChart() }
          { renderHistoricalSeriesChart() }
          <div className={ styles.actionWrapper }>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={ handleShowData }
              className={ styles.actionButton }
            >
              <TableChartIcon />
              <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
            </Button>
          </div>
        </Fragment>
      }
      { isEmpty &&
        <div className={ styles.infoBox }>
          <p>Nenhum resultado encontrado.</p>
        </div>
      }
    </Fragment>
  );
}
