export default {
  'default': {
    'territorial': [
      'indonesia_limit',
      'island_group',
      'province',
      'district',
      'subdistrict',
      'village',
      'new_national_capital',
    ],
    'land': [
      'forest_estate',
      'conservation_forest',
      'peat_ecosystem_function',
      'primary_forests_and_peatlands_moratorium',
      'watershed',
      'social_forestry',
      'ecossystem_restoration',
    ],
    'concession': [
      'pulpwood_concession',
      'selective_logging_concession',
      'mining_concession',
      'oil_Palm_concession',
    ]
  },
  'simplified': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ]
  },
  'infrastructure': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ]
  },
  'fire': {
    'territorial': [
      'indonesia_limit',
      'island_group',
      'province',
      'district',
      'subdistrict',
      'village',
      'new_national_capital',
    ],
    'land': [
      'forest_estate',
      'conservation_forest',
      'peat_ecosystem_function',
      'primary_forests_and_peatlands_moratorium',
      'watershed',
      'social_forestry',
      'ecossystem_restoration',
    ],
    'concession': [
      'pulpwood_concession',
      'selective_logging_concession',
      'mining_concession',
      'oil_Palm_concession',
    ]
  },
  'soil': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
    ]
  },
  'sentinel': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
      'special_territories',
    ],
    'land': [
      'settlement',
      'quilombo',
      'indigenous_land_new',
      'federal_conservation_units_integral_protection',
      'federal_conservation_units_sustainable_use',
      'state_conservation_units_integral_protection',
      'state_conservation_units_sustainable_use',
    ]
  }
};
