import React, { useEffect, useState, Fragment } from 'react';
import _ from 'lodash';
import numbro from 'numbro';
import { useQuery } from '@apollo/react-hooks';

import { FormattedMessage } from 'react-intl';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableChartIcon from '@material-ui/icons/TableChart';

import BarChart from '../../components/BarChart';
import PieChart from '../../components/PieChart';

import customClient from '../../../../../../../../core/custom-apollo-client/custom-apollo-client';

import useFormatMessage from '../../../../../../../../hooks/useFormatMessage';

import styles from '../../StatisticsBox.module.scss';

import {
  GET_BASE_DATA,
  GET_CLASS_TREE_DATA,
} from './query';

export default function AccumulatedDistributedStatisticsContent({
  clientType,
  activeYear,
  baseParams,
  classTreeKey,
  statisticsDataIsVisible,
  onShowData,

}) {
  const locale = localStorage.getItem('locale') || 'id';
  const formatMessage = useFormatMessage();

  const fromYear = _.first(activeYear) || 1985;
  const toYear = _.isArray(activeYear) ? _.last(activeYear) : activeYear;
  const activeClassTreeNodeIds = _.get(baseParams, 'activeClassTreeNodeIds');
  const territories = _.get(baseParams, 'territories');
  const territoryIds = _.map(territories, 'id');

  const [chartsData, setChartsData] = useState(null);
  const [emptyData, setEmptyData] = useState(false);

  const { data: classTreeData, loading: loadingClassTreeData } = useQuery(GET_CLASS_TREE_DATA, {
    variables: {
      classTreeKey
    },
    client: clientType === 'custom' ? customClient : undefined
  });
  const classTreeLevelsList = _.get(classTreeData, 'classTreeByKey[0].mvClassTreeLevelsUi');
  const filteredActiveClassTreeNodeIds = _.filter(activeClassTreeNodeIds, (nodeId) => {
    const nodeData = _.find(classTreeLevelsList, { id: nodeId });
    const childrenIds = _.get(nodeData, 'childrenIds');

    if (_.isEmpty(childrenIds)) {
      return true;
    } else {
      return !childrenIds.some(id => activeClassTreeNodeIds.indexOf(id) >= 0);
    }
  });

  const { data: baseData, loading, error: errorBaseData } = useQuery(GET_BASE_DATA, {
    variables: {
      classTreeNodeIds: filteredActiveClassTreeNodeIds,
      fromYear,
      toYear,
      territoriesIds: territoryIds
    },
    skip: loadingClassTreeData,
    client: clientType === 'custom' ? customClient : undefined
  });

  const getTotalAreaByClassesData = () => {
    const data = _.get(baseData, 'frequencyAccumulated');
    const years = _.sortBy(_.uniq(_.map(data, 'year')));

    const classTreeNodeData = {};

    _.each(activeClassTreeNodeIds, (nodeId) => {
      _.each(years, (year) => {
        const nodeData = _.find(data, { year, classTreeNodeId: nodeId });
        const value = _.get(nodeData, 'areaInHa') || null;
        const nodeValues = _.get(classTreeNodeData, nodeId);

        if (nodeValues) {
          _.set(classTreeNodeData, nodeId, [...nodeValues, value]);
        } else {
          _.set(classTreeNodeData, nodeId, [value]);
        }
      });
    });

    const parsedSeries = _.map(_.keys(classTreeNodeData), (nodeId) => {
      const parsedNodeId = Number(nodeId);
      const nodeData = _.find(classTreeLevelsList, { id: parsedNodeId });
      const stringList = _.get(nodeData, 'i18nStrings');
      const selectedString = _.find(stringList, { language: locale });
      const label = _.get(selectedString, 'stringValue');
      const prettyIndexOfNodeInTree = _.join(_.get(nodeData, 'positionInTree'), '.');

      return {
        nodeId: parsedNodeId,
        stack: 'year',
        name: `${ prettyIndexOfNodeInTree }. ${ label }`,
        color: _.get(nodeData, 'color') || '#e74c3c',
        data: _.get(classTreeNodeData, nodeId),
      };
    });
    const sortedSeries = _.sortBy(parsedSeries, 'name');
    const filteredSeries = _.filter(sortedSeries, ({ nodeId }) => _.includes(filteredActiveClassTreeNodeIds, nodeId));

    return {
      categories: years,
      series: sortedSeries,
      filteredSeries,
    };
  };

  const getTotalAreaByClassesOnFirstLevelData = () => {
    const filteredSeries = _.get(getTotalAreaByClassesData(), 'filteredSeries');

    return _(classTreeLevelsList)
      .filter({ level: 1 })
      .map((node) => {
        const nodeId = _.get(node, 'id');
        const childrenIds = _.get(node, 'childrenIds');
        let totalValue = _.last(_.get(_.find(filteredSeries, { nodeId }), 'data'));

        if (!totalValue) {
          totalValue = _.sumBy(childrenIds, (childrenId) => {
            return _.last(_.get(_.find(filteredSeries, { nodeId: childrenId }), 'data'));
          });
        }

        const stringList = _.get(node, 'i18nStrings');
        const selectedString = _.find(stringList, { language: locale });
        const label = _.get(selectedString, 'stringValue');
        const prettyIndexOfNodeInTree = _.join(_.get(node, 'positionInTree'), '.');

        return {
          name: `${ prettyIndexOfNodeInTree }. ${ label }`,
          value: totalValue,
          itemStyle: {
            color: _.get(node, 'color')
          },
        }
      })
      .value();
  };

  useEffect(() => {
    const sampleData = _.get(baseData, 'frequencyAccumulated');

    if ((!loading && errorBaseData) || (!loading &&_.isEmpty(sampleData))) {
      setEmptyData(true);
    } else {
      setEmptyData(false);
    }

    if (!loadingClassTreeData && !loading && baseData) {
      setChartsData({
        'totalAreaByClasses': getTotalAreaByClassesData(),
        'totalAreaByClassesOnFirstLevel': getTotalAreaByClassesOnFirstLevelData(),
      });
    }
  }, [baseData, loading, loadingClassTreeData, errorBaseData]);

  useEffect(() => {
    if (statisticsDataIsVisible) {
      handleShowData();
    }
  }, [chartsData]);

  const totalAreaByClassesOnFirstLevel = () => {
    const values = _.get(chartsData, 'totalAreaByClassesOnFirstLevel') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }>
          <FormattedMessage id="mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title" values={{ year: toYear }} />
        </h2>
        <PieChart
          serie={{
            title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.unit'),
            data: values
          }}
        />
      </div>
    );
  };

  const totalAreaByClasses = () => {
    const categories = _.get(chartsData, 'totalAreaByClasses.categories') || [];
    const series = _.get(chartsData, 'totalAreaByClasses.filteredSeries') || [];

    return (
      <div className={ styles.section }>
        <h2 className={ styles.secondaryTitle }><FormattedMessage id="mapbiomas.statistics.burned.accumulated.distributed_by_class_title" /></h2>
        <BarChart
          categories={ categories }
          series={ series }
        />
      </div>
    );
  };

  const handleShowData = () => {
    const classViewChartData = _.get(chartsData, 'totalAreaByClassesOnFirstLevel');
    const classViewChart = {
      title: formatMessage('mapbiomas.dashboard.coverage.coverage_main.charts.classes_pie.title', { year: toYear }),
      columns: ['Total'],
      rows: _.map(classViewChartData, (item) => {
        return {
          name: _.get(item, 'name'),
          data: [_.get(item, 'value')],
        };
      }),
    };

    const totalAreaByClassesChartData = _.get(chartsData, 'totalAreaByClasses');
    const totalAreaByClassesChartDataCategories = _.get(totalAreaByClassesChartData, 'categories') || [];
    const totalAreaByClassesChartDataSeries = _.get(totalAreaByClassesChartData, 'series') || [];

    const totalAreaByClassesChart = {
      title: <FormattedMessage id="mapbiomas.anual_statistics_content.area_title" />,
      columns: totalAreaByClassesChartDataCategories,
      rows: totalAreaByClassesChartDataSeries
    };

    onShowData([
      classViewChart,
      totalAreaByClassesChart
    ]);
  };

  const renderData = () => {
    if (emptyData) {
      return null;
    }

    return (
      <Fragment>
        { totalAreaByClassesOnFirstLevel() }
        { totalAreaByClasses() }
        <div className={ styles.actionWrapper }>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={ handleShowData }
            className={ styles.actionButton }
          >
            <TableChartIcon />
            <span><FormattedMessage id="mapbiomas.statistics.view_data" /></span>
          </Button>
        </div>
      </Fragment>
    );
  };

  return (
    <Fragment>
      { loading &&
        <div className={ styles.progressWrapper }>
          <LinearProgress />
        </div>
      }
      { emptyData &&
        <div className={ styles.infoBox }>
          <p><FormattedMessage id="mapbiomas.statistics.processing_data" /></p>
        </div>
      }
      { renderData() }
    </Fragment>
  );
}
