import React from 'react';
import { FormattedMessage } from 'react-intl';

import useWindowSize from '../../../../../../hooks/useWindowSize';

import styles from './InstitutionalMenu.module.scss';

const LOGO_HEIGHT = 70;
const TITLE_HEIGHT = 50;
const FOOTER_HEIGHT = 30;

export default function InstitutionalMenu({
  iframeMode,
}) {
  const windowSize = useWindowSize();
  const contentHeight = windowSize.height - (!iframeMode ? LOGO_HEIGHT : 0) - TITLE_HEIGHT - FOOTER_HEIGHT;

  return (
    <div>
      <h2 className={ styles.title }><FormattedMessage id="mapbiomas.sidebar.institutional.title" /></h2>
      <div className={ styles.navListWrapper } style={{ height: contentHeight }}>
        <ul className={ styles.navList }>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.title" /></span>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/selayang">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.project" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/produtos">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.products" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/quem-somos">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.about" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/tim">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.team" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/initiatives">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.other_initiatives" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/termofuse">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.about_mapbiomas.links.terms_of_use" />
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.maps_and_data.title" /></span>
            <ul>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/downloads">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.maps_and_data.links.downloads" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/termofuse">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.maps_and_data.links.terms_of_use" />
                </a>
              </li> */}
            </ul>
          </li>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.title" /></span>
            <ul>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/visao-geral-da-metodologia">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.methodology_overview" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/page/methodology-atbd">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.atdb" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/metodo-desmatamento">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.deforestation_method" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/ferramentas">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.tools" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/earthengine">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.google_earth" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/accuracy">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.accuracy_analysis" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/glossario">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.glossary" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/termofuse">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.terms_of_use" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/faq">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.methodology.links.qa" />
                </a>
              </li>
            </ul>
          </li>
          <li>
            <span><FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.title" /></span>
            <ul>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/lancamentos">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.releases" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/news">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.news" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/oportunidades">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.opportunities" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/premio">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.award" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/artigos-mapbiomas-1">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.articles" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/relatorios">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.reports" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/videos">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.videos" />
                </a>
              </li>
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.org/nota-t">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.technical_notes" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/termofuse">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.terms_of_use" />
                </a>
              </li>
              {/* <li>
                <a target="_blank" rel="noopener noreferrer" href="http://forum.mapbiomas.ecostage.com.br/">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.forum" />
                </a>
              </li> */}
              <li>
                <a target="_blank" rel="noopener noreferrer" href="https://mapbiomas.nusantara.earth/kontak">
                  <FormattedMessage id="mapbiomas.sidebar.institutional.menu.communication.links.contact" />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
}
